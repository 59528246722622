import React, { useState } from 'react';
import './EmailComposeForm.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill CSS for the Snow theme
import 'react-quill/dist/quill.bubble.css'; // Quill CSS for the Bubble theme


function EmailComposeForm() {
    const initialState = {
        to: '',
        from: '',
        subject: '',
        message: '',
        attachment: null
    };

    const [emailData, setEmailData] = useState(initialState);
    const [feedback, setFeedback] = useState('');
    const [isError, setIsError] = useState(false);

    const handleInputChange = (e) => {
        setEmailData({ ...emailData, [e.target.name]: e.target.value });
    };

    const handleMessageChange = (value) => {
        setEmailData({ ...emailData, message: value });
    };


    const handleAttachmentChange = (e) => {
        setEmailData({ ...emailData, attachment: e.target.files[0] });
    };

    const resetForm = () => {
        setEmailData(initialState);
    };

    const staticMessage = `Dear User,

    We hope this email finds you well.

    Thanks & Regards,
    ININDIA CORPORATION`;


    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            ['link', 'image', 'video', 'formula'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['blockquote', 'code-block'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        'font',
        'header',
        'bold', 'italic', 'underline', 'strike',
        'align',
        'link', 'image', 'video', 'formula',
        'list', 'bullet', 'indent',
        'blockquote', 'code-block',
        'script',
        'color', 'background'
    ];


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('to', emailData.to);
        formData.append('from', emailData.from);
        formData.append('subject', emailData.subject);
        formData.append('message', emailData.message);

        // Check if an attachment is selected
        if (emailData.attachment) {
            formData.append('attachment', emailData.attachment, emailData.attachment.name);
        }

        const token = sessionStorage.getItem('token');

        try {
            const response = await fetch('https://api.inindia.cloud/api/send-email', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify(emailData)

            });

            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message || 'Failed to send email');
            }

            resetForm(); // Reset the form after successful submission
            setFeedback('Email sent successfully!');
            setIsError(false);
        } catch (error) {
            setIsError(true);
            setFeedback(error.message);
        }
    };



    return (
        <div className="email-compose-container">
            <h2>Compose Email</h2>
            <form onSubmit={handleSubmit} className="email-compose-form">
                <input
                    type="email"
                    name="to"
                    value={emailData.to}
                    onChange={handleInputChange}  // Used here
                    placeholder="To"
                    required
                />
                <input
                    type="email"
                    name="from"
                    value={emailData.from}
                    onChange={handleInputChange}
                    placeholder="From"
                    required
                    disabled
                />
                <input
                    type="text"
                    name="subject"
                    value={emailData.subject}
                    onChange={handleInputChange}  // Used here
                    placeholder="Subject"
                    required
                />
                <ReactQuill
                    value={emailData.message}
                    onChange={handleMessageChange}
                    modules={modules}
                    formats={formats}
                    placeholder="Compose your email here"
                />
                <input
                    type="file"
                    name="attachment"
                    onChange={handleAttachmentChange}  // Used here
                />
                <button type="submit">Send Email</button>
            </form>
            {feedback && (
                <div className={`feedback-message ${isError ? 'error' : 'success'}`}>
                    {feedback}
                </div>
            )}
        </div>
    );
}

export default EmailComposeForm;
