import React from 'react';
import './Welcome.css'; // Make sure to create a corresponding CSS file

const Welcome = () => {
    const userName = sessionStorage.getItem('name') || 'Employee'; // Get the user's name from session storage or use a default
    return (
        <div className="welcome-container">
            <h1>Welcome, {userName}!</h1>
            <p>Welcome to the Employee Portal. Here you can manage your profile, view updates, and access various employee resources.</p>
            <div className="welcome-instructions">
                <h2>Getting Started</h2>
                <ul>
                    <li>Update your profile for the latest information.</li>
                    <li>Check out the latest company news and updates.</li>
                    <li>Access your work tools and resources easily.</li>
                </ul>
            </div>
            <div className="welcome-footer">
                <p>Need help? Contact the IT support team for assistance.</p>
            </div>
        </div>
    );
};

export default Welcome;
