import React from 'react';

function Pagination({ onChange }) {
  const handlePrevClick = () => {
    // Handle previous page click
    onChange({ type: 'prev' });
  };

  const handleNextClick = () => {
    // Handle next page click
    onChange({ type: 'next' });
  };

  return (
    <div>
      <button onClick={handlePrevClick}>Previous</button>
      <button onClick={handleNextClick}>Next</button>
    </div>
  );
}

export default Pagination;
