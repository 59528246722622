// components/OfferLetterForm.js
import { useState } from 'react';

export default function OfferLetterForm() {
  const [candidateName, setCandidateName] = useState('');

  const handleGenerateOfferLetter = () => {
    // Here you can implement the logic to generate the offer letter with the candidateName
    alert(`Generating offer letter for ${candidateName}`);
    // You can perform any additional logic here, like submitting the form data to a backend
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <h2 className="text-xl font-bold mb-4">Generate Offer Letter</h2>
      <form onSubmit={handleGenerateOfferLetter}>
        <div className="mb-4">
          <label htmlFor="candidateName" className="block text-sm font-medium text-gray-700">Candidate Name</label>
          <input
            id="candidateName"
            type="text"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            placeholder="Enter candidate name"
            value={candidateName}
            onChange={(e) => setCandidateName(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Generate Offer Letter
        </button>
      </form>
    </div>
  );
}
