import React, { useState } from 'react';
import './CreateUser.css';

function CreateUser() {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        role: ''
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token');
        setFeedbackMessage('');
        setIsError(false);

        try {
            const response = await fetch('https://api.inindia.cloud/api/register', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                throw new Error('Failed to create user');
            }

            // const result = await response.json();
            setFeedbackMessage('User created successfully');
        } catch (error) {
            setIsError(true);
            setFeedbackMessage(error.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className="create-user-container">
            <h2>Create User</h2>
            <form onSubmit={handleSubmit} className="row g-3 create-user-form">
                <div className="col-md-6">
                    <label htmlFor="name" className="create-user-label">Name:</label>
                    <input
                        type="text"
                        className="form-control create-user-input"
                        id="name"
                        name="name"
                        value={userData.name}
                        onChange={handleChange}
                        placeholder="Name"
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="email" className="create-user-label">Email:</label>
                    <input
                        type="email"
                        className="form-control create-user-input"
                        id="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        placeholder="Email"
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="password" className="create-user-label">Password:</label>
                    <input
                        type="password"
                        className="form-control create-user-input"
                        id="password"
                        name="password"
                        value={userData.password}
                        onChange={handleChange}
                        placeholder="Password"
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="role" className="create-user-label">Role:</label>
                    <input
                        type="text"
                        className="form-control create-user-input"
                        id="role"
                        name="role"
                        value={userData.role}
                        onChange={handleChange}
                        placeholder="Role"
                    />
                </div>
                <div className="col-12">
                    <button type="submit" className="btn btn-primary create-user-btn">Create User</button>
                </div>
            </form>
    
            {feedbackMessage && (
                <div className={`feedback-message ${isError ? 'error' : 'success'}`}>
                    {feedbackMessage}
                </div>
            )}
        </div>
    );
    
    
}

export default CreateUser;
