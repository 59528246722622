import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ConversationForm.css';

const ConversationForm = ({ leadId, onClose }) => {
    const [conversationNote, setConversationNote] = useState('');
    const [conversationDate, setConversationDate] = useState('');
    const [followUpAction, setFollowUpAction] = useState('');
    const [followUpDate, setFollowUpDate] = useState('');
    const [conversationMedium, setConversationMedium] = useState('');
    const [conversationStatus, setConversationStatus] = useState('');
    const [behaviorRating, setBehaviorRating] = useState('');
    const [professionalismRating, setProfessionalismRating] = useState('');
    const [qualityRating, setQualityRating] = useState('');
    const [financialIndicatorRating, setFinancialIndicatorRating] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Constructing the conversation data to send
        const conversationData = {
            leadId,
            conversationNote,
            conversationDate,
            followUpAction,
            followUpDate,
            conversationMedium,
            conversationStatus,
            behaviorRating,
            professionalismRating,
            qualityRating,
            financialIndicatorRating,
        };
        console.log("call started");
    
        try {
            setLoading(true);
            const token = sessionStorage.getItem('token');
            const response = await axios.post('https://api.inindia.cloud/api/reporting/conversation', conversationData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            // Assuming response.data contains the saved conversation
            console.log('Conversation saved successfully:', response.data);
            
            // Optionally, you can map the response data into form fields if you want to display the saved data after submission
            const savedConversation = response.data;
            setConversationNote(savedConversation.conversation_text || '');
            setConversationDate(savedConversation.conversation_date || '');
            setFollowUpAction(savedConversation.follow_up_action || '');
            setFollowUpDate(savedConversation.follow_up_date || '');
            setConversationMedium(savedConversation.conversation_medium || '');
            setConversationStatus(savedConversation.conversation_status || '');
            setBehaviorRating(savedConversation.behavior_rating || '');
            setProfessionalismRating(savedConversation.professionalism_rating || '');
            setQualityRating(savedConversation.quality_rating || '');
            setFinancialIndicatorRating(savedConversation.financial_indicator_rating || '');
    
            // Close the form after successful submission
            onClose();
        } catch (error) {
            // Handle error
            console.error('Error saving conversation:', error.response?.data?.message || error.message);
            setError('Failed to save conversation. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    const handleNoteChange = (value) => {
        setConversationNote(value);
    };

    const quillModules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean']
            ],
        }
    };

    return (
        <div className="conversation-popup">
            <div className="conversation-popup-inner">
                <h3>Capture Conversation Details for Lead {leadId}</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        Conversation Medium:
                        <select
                            value={conversationMedium}
                            onChange={(e) => setConversationMedium(e.target.value)}
                            required
                        >
                            <option value="">Select Medium</option>
                            <option value="Phone">Phone</option>
                            <option value="Email">Email</option>
                            <option value="Chat">Chat</option>
                            <option value="In Person">In Person</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                    <label>
                        Conversation Notes:
                        <ReactQuill
                            theme="snow"
                            value={conversationNote}
                            onChange={handleNoteChange}
                            placeholder="Enter details of the conversation"
                            modules={quillModules}
                        />

                    </label>
                    <label>
                        Date of Conversation:
                        <input
                            type="date"
                            value={conversationDate}
                            onChange={(e) => setConversationDate(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Follow-up Action:
                        <textarea
                            value={followUpAction}
                            onChange={(e) => setFollowUpAction(e.target.value)}
                            placeholder="Describe the follow-up action required"
                            required
                        />
                    </label>
                    <label>
                        Follow-up Date:
                        <input
                            type="date"
                            value={followUpDate}
                            onChange={(e) => setFollowUpDate(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Status of Conversation:
                        <select
                            value={conversationStatus}
                            onChange={(e) => setConversationStatus(e.target.value)}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="Completed">Completed</option>
                            <option value="Pending">Pending</option>
                            <option value="Ongoing">Ongoing</option>
                        </select>
                    </label>

                    {/* Rating Fields */}
                    <label>
                        Behavior Rating:
                        <select
                            value={behaviorRating}
                            onChange={(e) => setBehaviorRating(e.target.value)}
                            required
                        >
                            <option value="">Select Behavior Rating</option>
                            <option value="Excellent">Excellent</option>
                            <option value="Good">Good</option>
                            <option value="Average">Average</option>
                            <option value="Needs Improvement">Needs Improvement</option>
                        </select>
                    </label>
                    <label>
                        Professionalism Rating:
                        <select
                            value={professionalismRating}
                            onChange={(e) => setProfessionalismRating(e.target.value)}
                            required
                        >
                            <option value="">Select Professionalism Rating</option>
                            <option value="Exceptional">Exceptional</option>
                            <option value="Respectful">Respectful</option>
                            <option value="Neutral">Neutral</option>
                            <option value="Unprofessional">Unprofessional</option>
                        </select>
                    </label>
                    <label>
                        Quality Rating:
                        <select
                            value={qualityRating}
                            onChange={(e) => setQualityRating(e.target.value)}
                            required
                        >
                            <option value="">Select Quality Rating</option>
                            <option value="Outstanding">Outstanding</option>
                            <option value="High">High</option>
                            <option value="Satisfactory">Satisfactory</option>
                            <option value="Low">Low</option>
                        </select>
                    </label>
                    <label>
                        Financial Indicator Rating:
                        <select
                            value={financialIndicatorRating}
                            onChange={(e) => setFinancialIndicatorRating(e.target.value)}
                            required
                        >
                            <option value="">Select Financial Rating</option>
                            <option value="Strong">Strong</option>
                            <option value="Stable">Stable</option>
                            <option value="Moderate">Moderate</option>
                            <option value="Weak">Weak</option>
                            <option value="N/A">N/A</option>
                        </select>
                    </label>

                    {loading ? <p>Saving...</p> : <button type="submit" className="save-button">Save Conversation</button>}
                    {error && <p className="error-message">{error}</p>}
                </form>
                <button className="cancel-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ConversationForm;
