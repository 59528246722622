import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ArticleEditor.css';

function ArticleEditor({ article, onClose }) {
  const [editedArticle, setEditedArticle] = useState({ ...article });
  const [description, setDescription] = useState(article.description);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

 useEffect(() => {
  fetchTopics();
}, [article]);

const updateEditedArticle = (key, value) => {
  setEditedArticle(prev => ({ ...prev, [key]: value }));
};

  const generateTopicImage = async () => {
    setLoading(true);
    try {
      const topicName = 'inindiatech.com';  // Only this topic name is allowed

      const response = await fetch('https://api.inindia.cloud/api/generate-topic-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token if required
        },
        body: JSON.stringify({ topicName }), // Pass the correct topic name
      });

      const data = await response.json();

      if (response.ok) {
        const newImageUrl = data.imageUrl;
        setEditedArticle((prevArticle) => ({
          ...prevArticle,
          topic_img: newImageUrl,  // Update topic_img in state
        }));
      } else {
        setError('Failed to generate image. Please try again.');
      }

    } catch (error) {
      console.error('Error generating topic image:', error);
      setError('An error occurred while generating the image. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchTopics = async () => {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await fetch('https://api.inindia.cloud/api/articles/topics', { headers });
      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }
      const data = await response.json();
      setTopics(data.topics);
    } catch (error) {
      console.error('Error fetching topics:', error);
      setError('Error fetching topics. Please try again.');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedArticle({ ...editedArticle, [name]: value || '' });
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(`https://api.inindia.cloud/api/articles/${editedArticle.id}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ ...editedArticle, description: description }),
      });

      if (!response.ok) {
        throw new Error('Failed to update article');
      }

      const data = await response.json();
      console.log('Article updated successfully:', data);
      onClose();
    } catch (error) {
      setError('Error updating article. Please try again.');
      console.error('Error updating article:', error);
    } finally {
      setLoading(false);
    }
  };

  const quillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {}
    }
  };

  return (
    <div className="article-editor-modal">
      <div className="popup-inner">
        <div className="article-editor-content m-4">
          <form onSubmit={handleSave}>
            <div className="form-grid">
              {/* Row 1 */}
              <div className="form-row">
                {/* Distribution */}
                <div className="form-field">
                  <label>Distribution:</label>
                  <select name="distribution" value={editedArticle.distribution} onChange={handleInputChange} required>
                    <option value="inindiatech.com">inindiatech.com</option>
                  </select>
                </div>

                {/* URL */}
                <div className="form-field">
                  <label>URL:</label>
                  <input type="text" name="url" value={editedArticle.url} onChange={handleInputChange} placeholder="Enter URL..." required />
                </div>

                {/* Category */}
                <div className="form-field">
                  <label>Category:</label>
                  <select name="cat" value={editedArticle.cat} onChange={handleInputChange} required>
                    <option value="">Select Category</option>
                    {topics.map(topic => (
                      <option key={topic.id} value={topic.id}>{topic.topic}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Row 2 */}
              <div className="form-row">
                {/* Page Name */}
                <div className="form-field">
                  <label>Page Name:</label>
                  <input type="text" name="page_name" value={editedArticle.page_name} onChange={handleInputChange} placeholder="Enter page name..." required />
                </div>

                {/* Subject */}
                <div className="form-field">
                  <label>Subject:</label>
                  <input type="text" name="subject" value={editedArticle.subject} onChange={handleInputChange} placeholder="Enter subject..." required />
                </div>

                {/* Short Description */}
                <div className="form-field">
                  <label>Short Description:</label>
                  <textarea
                    name="short_desc"
                    value={editedArticle.short_desc}
                    onChange={handleInputChange}
                    placeholder="Enter short description..."
                    required
                  />
                </div>
              </div>

              {/* Row 3 */}
              <div className="form-row">
                {/* Long Description */}
                <div className="form-field">
                  <label>Long Description:</label>
                  <textarea
                    name="long_desc"
                    value={editedArticle.long_desc}
                    onChange={handleInputChange}
                    placeholder="Enter long description..."
                    required
                  />
                </div>

                {/* Topic Image */}
                <div className="form-field">
                  <label>Topic Image:</label>
                  <div className="topic-image-container">
                    <input 
                      type="text" 
                      name="topic_img" 
                      value={editedArticle.topic_img} 
                      onChange={handleInputChange} 
                      placeholder="Enter topic image URL..." 
                      required 
                    />
                   <button type="button" onClick={generateTopicImage} className="generate-button" disabled={loading}>
  {loading ? "Generating..." : "Generate Image"}
</button>

                  </div>
                </div>

                {/* Keywords */}
                <div className="form-field">
                  <label>Keywords:</label>
                  <input type="text" name="keywords" value={editedArticle.keywords} onChange={handleInputChange} placeholder="Enter keywords..." required />
                </div>
              </div>
            </div>

            {/* Rich Text Editor */}
            <div className="description">
              <ReactQuill
                theme="snow"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Start typing your description here..."
                modules={quillModules}
              />
            </div>

            {/* Save and Cancel buttons */}
            <div className="form-buttons">
              {loading ? <p>Saving...</p> : <button type="submit" className="save-button">Save</button>}
              <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
              {error && <p className="error-message">{error}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ArticleEditor;
