import React, { useState } from 'react';
import './Profile.css'; // Import custom CSS file for styling

const Profile = () => {
    const [formData, setFormData] = useState({
        Date_Submitted: '',
        First_Name: '',
        Last_Name: '',
        Your_Email: '',
        Company_Email: '',
        Phone: '',
        Current_Address: '',
        Permanent_Address: '',
        City: '',
        State: '',
        Zip: '',
        Skills: '',
        Interest: '',
        Interest_to_join_as: '',
        Previous_Job_Title: '',
        Date_Previous_Job_Started: '',
        Date_Previous_Job_Ended: '',
        Previous_Job_Description: '',
        Name_1: '',
        Phone_1: '',
        Name_2: '',
        Phone_2: '',
        Name_3: '',
        Phone_3: '',
        level: '',
        offer_letter: '',
        contract: '',
        account_creation: '',
        manager: '',
        Project: '',
        project_available: '',
        git_profile: '',
        Gender: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Replace 'apiEndpoint' with your actual API endpoint
            const response = await fetch('apiEndpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                // Handle success
                console.log('Form submitted successfully');
            } else {
                // Handle error
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <h2>Create Profile</h2>
            <form onSubmit={handleSubmit} className="profile-form">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="first_name" className="form-label">First Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="First_Name"
                            value={formData.First_Name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="last_name" className="form-label">Last Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="Last_Name"
                            value={formData.Last_Name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="your_email" className="form-label">Your Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            id="your_email"
                            name="Your_Email"
                            value={formData.Your_Email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="company_email" className="form-label">Company Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            id="company_email"
                            name="Company_Email"
                            value={formData.Company_Email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                {/* Add more form fields similarly */}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default Profile;
