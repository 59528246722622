import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './User.css'; // Make sure to create this CSS file
import EditForm from './EditUser';

function User() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch(`https://api.inindia.cloud/api/users/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setUser(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleUpdate = async (formData) => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`https://api.inindia.cloud/api/users/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // Update was successful
                setUpdateSuccess(true);
                toggleEdit(); // Exit edit mode
                const updatedUser = await response.json();
                setUser(updatedUser); // Update user data
            } else {
                // Update failed, handle errors
                setUpdateSuccess(false);
                const responseData = await response.json();
                throw new Error(`HTTP error! status: ${response.status}, message: ${responseData.message}`);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm(`Are you sure to delete "${user.name}" account from this organisation?`);

        if (confirmDelete) {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch(`https://api.inindia.cloud/api/users/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    // Deletion was successful
                    setDeleteSuccess(true);
                    // Handle any further actions after successful deletion, e.g., redirection
                } else {
                    // Deletion failed, handle errors
                    setDeleteSuccess(false);
                    const responseData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${responseData.message}`);
                }
            } catch (err) {
                setError(err.message);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="user-details">
            {/* Success messages and error handling... */}
    
            <div className="user-details-container">
                <div className="user-card">
                    <h2 className="user-name">{user.name}</h2>
                    <p className="user-detail"><strong>Email:</strong> {user.email}</p>
                    <p className="user-detail"><strong>Role:</strong> {user.role}</p>
                    
                    {/* Additional fields */}
                    {/* {user.age && <p className="user-detail"><strong>Age:</strong> {user.age}</p>}
                    {user.location && <p className="user-detail"><strong>Location:</strong> {user.location}</p>} */}
                    {/* Add more fields as needed */}
    
                    {/* User actions... */}
                    <div className="user-actions">
                        <button onClick={toggleEdit}>Edit</button>
                        <button onClick={handleDelete}>Delete</button>
                    </div>
    
                    {/* Edit form */}
                    {isEditing && <EditForm user={user} onUpdate={handleUpdate} onCancel={toggleEdit} />}
                </div>
            </div>
        </div>
    );
    
}

export default User;
