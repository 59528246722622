// LeadTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LeadsTable.css';
import LeadEditor from './LeadEditor';
import ConversationForm from '../forms/ConversationForm'; // Import the ConversationForm component

const LeadTable = () => {
    const [leads, setLeads] = useState([]);
    const [hoveredLeadId, setHoveredLeadId] = useState(null);
    const [isConversationFormOpen, setIsConversationFormOpen] = useState(false);
    const [selectedLeadId, setSelectedLeadId] = useState(null);

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get('https://api.inindia.cloud/api/get-leads', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const leadsArray = Array.isArray(response.data) ? response.data : [];
                setLeads(leadsArray);
            } catch (error) {
                console.error('Error fetching leads:', error.response?.data?.message || error.message);
            }
        };

        fetchLeads();
    }, []);

    const openConversationForm = (leadId) => {
        setSelectedLeadId(leadId);
        setIsConversationFormOpen(true);
    };

    const closeConversationForm = () => {
        setIsConversationFormOpen(false);
        setSelectedLeadId(null);
    };

    return (
        <div className="table-container">
            <h2>Available Leads</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Contact Number</th>
                        <th>Requirement</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Client Name</th>
                        <th>Business Name</th>
                        <th>Alternate Number</th>
                        <th>WhatsApp</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.length > 0 ? (
                        leads.map((lead) => (
                            <tr key={lead.id}>
                                <td>{lead.id}</td>
                                <td>{lead.contact_number}</td>
                                <td
                                    className="truncate-text"
                                    onMouseEnter={() => setHoveredLeadId(lead.id)}
                                    onMouseLeave={() => setHoveredLeadId(null)}
                                >
                                    {lead.requirement}
                                    {hoveredLeadId === lead.id && (
                                        <span className="tooltip-text">{lead.requirement}</span>
                                    )}
                                </td>
                                <td>{lead.lead_source}</td>
                                <td>{lead.status}</td>
                                <td>{lead.client_name}</td>
                                <td>{lead.business_name}</td>
                                <td>{lead.alternate_number}</td>
                                <td>{lead.whatsapp_number}</td>
                                <td>{lead.city}</td>
                                <td>{lead.state}</td>
                                <td>{new Date(lead.created_at).toLocaleDateString()}</td>
                                <td>{new Date(lead.updated_at).toLocaleDateString()}</td>
                                <td>
                                    <button onClick={() => openConversationForm(lead.id)}>Add Task</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="14">No leads found.</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Task Form Popup */}
            {isConversationFormOpen && <ConversationForm leadId={selectedLeadId} onClose={closeConversationForm} />}
        </div>
    );
};

export default LeadTable;
