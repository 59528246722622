// ArticleCreateForm.js
import React from 'react';

const ArticleCreateForm = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left section */}
      <div className="w-1/4 bg-gray-200 p-6">
        <h2 className="text-xl font-semibold">Left Section</h2>
        <p>This section occupies 1/4 of the width.</p>
      </div>
      {/* Middle section */}
      <div className="w-1/2 bg-gray-300 p-6">
        <h2 className="text-xl font-semibold">Middle Section</h2>
        <p>This section occupies 1/2 of the width.</p>
      </div>
      {/* Right section */}
      <div className="w-1/4 bg-gray-400 p-6">
        <h2 className="text-xl font-semibold">Right Section</h2>
        <p>This section occupies 1/4 of the width.</p>
      </div>
    </div>
  );
};

export default ArticleCreateForm;
