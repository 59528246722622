import React, { useState, useEffect } from 'react';
import './ProjectList.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';


function ListProjects() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch('https://api.inindia.cloud/api/projects', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setProjects(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (loading) {
        return <div>Loading projects...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="project-list">
            {projects.map(project => (
                <div key={project.id} className="project-card">
                    <h3 className="project-name">{project.project_name}</h3>
                    <p className="project-description">{project.project_description || '-'}</p>
                    <div className="project-details">
                        <p><strong>Status:</strong> {project.status === 0 ? 'Inactive' : 'Active'}</p>
                        <p><strong>Client Reference:</strong> {project.ref_client}</p>
                        <p><strong>Mobile Apps:</strong> {project.mobile_apps || '-'}</p>
                        <p><strong>Website:</strong> {project.website || '-'}</p>
                        <p><strong>Admin Panel:</strong> {project.admin_panel || '-'}</p>
                        <p><strong>Vendor Panel:</strong> {project.vendor_panel || '-'}</p>
                        <p><strong>Admin Panel Multilevel:</strong> {project.admin_panel_multilevel || '-'}</p>
                        <p>
                        <strong>Explore:</strong> <Link to={`${project.id}`}><FontAwesomeIcon icon={faExternalLinkAlt} /></Link>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ListProjects;
