import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ArticleWriter.css';

function ArticleWriter() {
  const [articleData, setArticleData] = useState({
    distribution: 'inindiatech.com',
    url: '',
    cat: '',
    page_name: '',
    subject: '',
    description: '',
    short_desc: '',
    long_desc: '',
    topic_img: '',
    keywords: '',
  });

  const clearArticleData = () => {
    setArticleData({
      distribution: '',
      url: '',
      cat: '',
      page_name: '',
      subject: '',
      description: '',
      short_desc: '',
      long_desc: '',
      topic_img: '',
      keywords: '',
    });
    setDescription('');
  };

  const [description, setDescription] = useState('');
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchTopics();
  }, []); // Run only once on component mount

  const fetchTopics = async () => {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await fetch('https://api.inindia.cloud/api/articles/topics', {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }
      const data = await response.json();
      setTopics(data.topics);
    } catch (error) {
      console.error('Error fetching topics:', error);
      setError('Error fetching topics. Please try again.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setArticleData({ ...articleData, [name]: value || '' });
  };

  const handledescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const dataToSend = { ...articleData };
      for (const key in dataToSend) {
        if (dataToSend[key] === undefined) {
          dataToSend[key] = '';
        }
      }

      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const response = await saveArticle(dataToSend, description, headers);

      if (!response.ok) {
        throw new Error('Failed to update article');
      }

      const data = await response.json();
      console.log('Article updated successfully:', data);

      clearArticleData();
      setError('');
      alert('Article submitted successfully!');
    } catch (error) {
      setError('Error updating article. Please try again.');
      console.error('Error updating article:', error);
    }
  };

  const quillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': ['sans-serif', 'serif', 'monospace', 'arial', 'times-new-roman', 'courier', 'helvetica', 'georgia', 'verdana', 'impact', 'comic-sans', 'tahoma', 'lucida', 'roboto', 'open-sans', 'lato', 'montserrat', 'oswald', 'palatino', 'ubuntu', 'futura', 'garamond', 'calibri', 'avenir', 'proxima-nova', 'arial-black', 'trebuchet-ms', 'cambria', 'franklin-gothic', 'baskerville', 'rockwell'] }],
        [{ 'align': [] }],
        ['link', 'image', 'video'], // Added 'video' option for embedding videos
        ['formula', 'emoji'], // Added 'formula' and 'emoji' options
        ['table'], // Added 'table' option for table management
        ['source'], // Added 'source' option for source code editing
        ['code'],
        ['undo', 'redo'], // Added 'undo' and 'redo' options
        ['clean'] // Clean formatting option
      ],
      
      handlers: {}
    }
  };

  const saveArticle = async (articleData, description, headers) => {
    return await fetch(`https://api.inindia.cloud/api/articles/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ ...articleData, description: description }),
    });
  };

  return (
    <div className="article-writer">
      <div className="top-bar">
        <div className="writing-tools">
          <button onClick={clearArticleData}>Clear Article Data</button>
        </div>
      </div>
      <div className="description">
        <ReactQuill
          theme="snow"
          value={description}
          onChange={handledescriptionChange}
          placeholder="Start typing your description here..."
          modules={quillModules}
        />
      </div>
      <div className="form">
        <form onSubmit={handleSave}>
          <label>
            Distribution:
            <select name="distribution" value={articleData.distribution} onChange={handleChange} required>
              <option value="inindiatech.com">inindiatech.com</option>
            </select>
          </label>

          <label>
            URL:
            <input type="text" name="url" value={articleData.url} onChange={handleChange} placeholder="Enter URL..." required />
          </label>
          <label>
            Category:
            <select name="cat" value={articleData.cat} onChange={handleChange} required>
              <option value="">Select Category</option>
              {topics.map(topic => (
                <option key={topic.id} value={topic.id}>{topic.topic}</option>
              ))}
            </select>
          </label>
          <label>
            Page Name:
            <input type="text" name="page_name" value={articleData.page_name} onChange={handleChange} placeholder="Enter page name..." required />
          </label>
          <label>
            Subject:
            <input type="text" name="subject" value={articleData.subject} onChange={handleChange} placeholder="Enter subject..." required />
          </label>

          <label>Short Description:</label>
          <textarea
            name="short_desc"
            value={articleData.short_desc}
            onChange={handleChange}
            placeholder="Enter short description..."
            style={{ width: '500px', fontSize: '14px' }}
            required
          />

          <label>Long Description:</label>
          <textarea
            name="long_desc"
            value={articleData.long_desc}
            onChange={handleChange}
            placeholder="Enter long description..."
            style={{ width: '500px', fontSize: '14px' }}
            required
          />

          <label>
            Topic Image:
            <input type="text" name="topic_img" value={articleData.topic_img} onChange={handleChange} placeholder="Enter topic image URL..." required />
          </label>
          <label>
            Keywords:
            <input type="text" name="keywords" value={articleData.keywords} onChange={handleChange} placeholder="Enter keywords..." required />
          </label>
          <button type="submit">Save Article</button>
          {error && <span className="error">{error}</span>}
        </form>
      </div>
    </div>
  );
}

export default ArticleWriter;
