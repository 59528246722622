import React, { useState } from 'react';
import './LoginIndex.css';
import '../EmpDashboard.css';


const Login = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [ProjectID, setProjectID] = useState('');
  const [TaskID, setTaskID] = useState('');
  const [estimatedLoginTime, setEstimatedLoginTime] = useState('');
  const [error, setError] = useState('');

  const handleCheckIn = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setError('');
    setProjectID('');
    setTaskID('');
    setEstimatedLoginTime('');
  };

  const handleConfirmCheckIn = () => {
    if (ProjectID && TaskID && estimatedLoginTime) {
      const estimatedHours = parseFloat(estimatedLoginTime);
      if (estimatedHours >= 1 && estimatedHours <= 8) {
        // Perform check-in logic (e.g., send data to server)
        // For demo purposes, assume check-in successful after a brief delay
        setTimeout(() => {
          setLoggedIn(true);
          setPopupOpen(false);
        }, 1000);
        setError('');
      } else {
        setError('Estimated login time must be between 1 and 8 hours');
      }
    } else {
      setError('Please fill in all fields');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  return (
    <div className="container mt-5">
      {loggedIn ? (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card text-center">
              <div className="card-body">
                <h2>Welcome back!</h2>
                <p>You are currently checked in.</p>
                <button className="btn btn-danger" onClick={handleLogout}>Check Out</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card text-center">
              <div className="card-body">
                <h2>Remote Job Check In</h2>
                <button className="btn btn-primary" onClick={handleCheckIn}>Check In</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {popupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <span className="close" onClick={handlePopupClose}>&times;</span>
            <div className="popup-content">
              <h2>Check In Details</h2>
              {error && <p className="error">{error}</p>}
              <input
                type="text"
                placeholder="Project ID"
                value={ProjectID}
                onChange={(e) => setProjectID(e.target.value)}
                className="form-control mb-3"
              />
              <input
                type="text"
                placeholder="Task ID"
                value={TaskID}
                onChange={(e) => setTaskID(e.target.value)}
                className="form-control mb-3"
              />
              <input
                type="number"
                placeholder="Estimated Time (hours)"
                min="1"
                max="8"
                value={estimatedLoginTime}
                onChange={(e) => setEstimatedLoginTime(e.target.value)}
                className="form-control mb-3"
              />
              <button className="login-form button btn btn-success" onClick={handleConfirmCheckIn}>Confirm Check In</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
