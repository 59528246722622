import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import './WriterDashboard.css';
import Settings from '../pages/Settings';
import Welcome from '../employees/Welcome';
import LoginIndex from '../employees/forms/LoginIndex';
import Profile from '../employees/forms/Profile';
import ArticleList from '../managePages/ArticleList';
import ArticleWriter from '../managePages/ArticleWriter';

function WriterDashboard() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const email = sessionStorage.getItem('email');
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.removeItem('token');
    navigate('/');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="dashboard-container">
      <button className="toggle-button" onClick={toggleSidebar}>
        <i className={`fa ${sidebarOpen ? 'fa-times' : 'fa-bars'}`}></i>
      </button>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="welcome">Welcome</Link></li>
          <li><Link to="attendance">Attendance</Link></li>
          <li><Link to="profile">Profile</Link></li>
          <li><Link to="articles/create">Create Article</Link></li>
          <li><Link to="articles">Manage Articles</Link></li>
          <li><Link to="settings">Settings</Link></li>
          <li onClick={handleLogout}>Logout</li>
        </ul>

        <div className="user-info-bottom">
          <div className="user-info" onClick={toggleDropdown}>
            <div className="user-avatar">
              <i className="fa fa-user"></i>
            </div>
            <span>{email}</span>
            <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'up'}`}></i>
          </div>

          {dropdownOpen && (
            <div className={`dropdown-menu ${dropdownOpen ? 'visible' : ''}`}>
              <ul>
                <li><Link to="profile">Profile</Link></li>
                <li><Link to="settings">Settings</Link></li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="content">
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="attendance" element={<LoginIndex />} />
          <Route path="articles" element={<ArticleList />} />
          <Route path="articles/create" element={<ArticleWriter />} />
        </Routes>
      </div>
    </div>
  );
}

export default WriterDashboard;
