import React from 'react';
import './Profile.css'; // Make sure to create a corresponding CSS file

function Profile() {

    
    const email = sessionStorage.getItem('email');

    return (
        <div className="profile-container">
            <div className="profile-header">
                <img src="path-to-avatar.png" alt="User Avatar" className="profile-avatar" />
                <h2>User's Name</h2>
                <p>Email: {email}</p>
            </div>

            <div className="profile-details">
                <h3>Edit Profile</h3>
                <form className="profile-form">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" />
                    </div>

                    {/* Add more form fields as needed */}

                    <button type="submit" className="save-btn">Save Changes</button>
                </form>
            </div>
        </div>
    );
}

export default Profile;
