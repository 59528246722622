import React, { useState } from 'react';
import SearchComponent from '../search/index'; // Assuming this is a separate component

function TrafficFilters({ onChange, loading }) {
  const [dateRange, setDateRange] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Handle the change in the date range selection
  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
    // Set start and end dates based on selected date range
    switch (e.target.value) {
      case 'today':
        setStartDate(new Date().toISOString().split('T')[0]);
        setEndDate(new Date().toISOString().split('T')[0]);
        break;
      case 'yesterday':
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(yesterday.toISOString().split('T')[0]);
        setEndDate(yesterday.toISOString().split('T')[0]);
        break;
      // Add cases for other predefined date ranges here
      default:
        setStartDate('');
        setEndDate('');
        break;
    }
  };

  // Apply the selected filter values
  const handleFilterChange = () => {
    const today = new Date();
    const last7Days = new Date();
    last7Days.setDate(today.getDate() - 7);

    // Default start and end date when no date range is selected
    const defaultStartDate = startDate || last7Days.toISOString().split('T')[0];
    const defaultEndDate = endDate || today.toISOString().split('T')[0];

    // Passing the selected date range back to the parent component
    onChange({ startDate: defaultStartDate, endDate: defaultEndDate });
  };

  return (
    <div>
      <SearchComponent /> {/* Assuming this is used for searching */}

      <div className="card">
        <label htmlFor="dateRange">Select Date Range:</label>
        <select id="dateRange" value={dateRange} onChange={handleDateRangeChange}>
          <option value="">Custom</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          {/* Add other predefined date range options here */}
        </select>

        {dateRange === '' && (
          <>
            <label htmlFor="startDate">Start Date:</label>
            <input 
              type="date" 
              id="startDate" 
              value={startDate} 
              onChange={(e) => setStartDate(e.target.value)} 
            />
            <label htmlFor="endDate">End Date:</label>
            <input 
              type="date" 
              id="endDate" 
              value={endDate} 
              onChange={(e) => setEndDate(e.target.value)} 
            />
          </>
        )}

        <button onClick={handleFilterChange}>Apply Filters</button>
        {loading && <p>Loading...</p>}
      </div>

    </div>
  );
}

export default TrafficFilters;
