// Dashboard.js

import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
// import './EmpDashboard.css';
import Settings from '../pages/Settings';
import Welcome from '../employees/Welcome';
import LoginIndex from '../employees/forms/LoginIndex';
import Profile from '../employees/forms/Profile';

function Dashboard() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const email = sessionStorage.getItem('email');
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.removeItem('token');
        navigate('/');
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLinkClick = () => {
        // Close sidebar explicitly on small screens after navigation
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    };

    return (
        <div className="dashboard-container">
            <button className="toggle-button" onClick={toggleSidebar}>
                <i className={`fa ${sidebarOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="welcome" onClick={handleLinkClick}>Welcome</Link></li>
                    <li><Link to="attendance" onClick={handleLinkClick}>Attendance</Link></li>
                    <li><Link to="profile" onClick={handleLinkClick}>Profile</Link></li>
                    <li><Link to="settings" onClick={handleLinkClick}>Settings</Link></li>
                    <li onClick={handleLogout}>Logout</li>
                </ul>

                <div className="user-info-bottom">
                    <div className="user-info" onClick={toggleDropdown}>
                        <div className="user-avatar">
                            <i className="fa fa-user"></i>
                        </div>
                        <span>{email}</span>
                        <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'up'}`}></i>
                    </div>

                    {dropdownOpen && (
                        <div className={`dropdown-menu ${dropdownOpen ? 'visible' : ''}`}>
                            <ul>
                                <li>Profile</li>
                                <li>Settings</li>
                                <li onClick={handleLogout}>Logout</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className="content">
                <Routes>
                    <Route path="profile" element={<Profile />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="welcome" element={<Welcome />} />
                    <Route path="attendance" element={<LoginIndex />} />
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;
