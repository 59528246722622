import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Users.css'; // Make sure to create this CSS file

            function Users() {
                const [users, setUsers] = useState([]);
                const [filteredUsers, setFilteredUsers] = useState([]);
                const [loading, setLoading] = useState(true);
                const [error, setError] = useState(null);
                const [searchTerm, setSearchTerm] = useState('');
                const [filterType, setFilterType] = useState('name'); // New state for filter type
                
            

            
                useEffect(() => {
                    const fetchData = async () => {
                        try {
                            const token = sessionStorage.getItem('token'); // Adjust token key if necessary
                            const response = await fetch('https://api.inindia.cloud/api/users', {
                                method: 'GET',
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                }
                            });
            
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
            
                            const data = await response.json();
                            setUsers(data);
                        } catch (err) {
                            setError(err.message);
                        } finally {
                            setLoading(false);
                        }
                    };
            
                    fetchData();
                }, []);
            
                useEffect(() => {
                    const lowercasedTerm = searchTerm.toLowerCase();
                    const results = users.filter(user => {
                        switch (filterType) {
                            case 'name':
                                return user.name.toLowerCase().includes(lowercasedTerm);
                            case 'email':
                                return user.email.toLowerCase().includes(lowercasedTerm);
                            case 'role':
                                return user.role.toLowerCase().includes(lowercasedTerm);
                            default:
                                return true;
                        }
                    });
                    setFilteredUsers(results);
                }, [searchTerm, users, filterType]);


                if (loading) {
                    return <div>Loading...</div>;
                }
                
                if (error) {
                    return <div>Error: {error}</div>;
                }
                
                return (
                    <div className="users-container">
                        <Link to="create-user" className="create-user-button">Create New User</Link>
                        <h1>Users List</h1>
                        <div className="filter-section">
                            <select
                                className="filter-dropdown"
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                            >
                                <option value="name">Name</option>
                                <option value="email">Email</option>
                                <option value="role">Role</option>
                            </select>
                            <input 
                                type="text" 
                                placeholder={`Search by ${filterType}...`} 
                                className="search-input"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <ul className="users-list">
                            {filteredUsers.map(user => (  // Make sure to use 'filteredUsers' here
                                <li key={user.id} className="user-item">
                                    <h2>{user.name}</h2>
                                    <p>Email: {user.email}</p>
                                    <p>Role: {user.role}</p>
                                    <Link to={`user/${user.id}`} className="view-details-button">View Details</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
            
                );
            }
            
            export default Users;
            