import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import './Dashboard.css';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Users from '../pages/Users';
import User from '../pages/User';
import CreateUser from '../pages/CreateUser';
import EmailComposeForm from '../emails/EmailComposeForm';
import ListProjects from '../projects/ProjectList.js';
import ProjectDetails from '../projects/ProjectDetails.js';
import CreateProject from '../projects/CreateProject.js';
import LeadForm from '../leads/LeadForm.js';
import TrafficData from '../traffic/TrafficData.js';
import TrafficData1 from '../traffic/trafficList.js';
import OfferLetterForm from '../docs/OfferLetterForm';
import CreateArticle from '../forms/ArticleCreateForm.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ArticleList from '../managePages/ArticleList.js';
import ArticleWriter from '../managePages/ArticleWriter.js';
import LeadTable from '../leads/LeadsTable.js';
import TrafficTable from '../leads/TrafficTable.js';

function Dashboard() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const email = sessionStorage.getItem('email');
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLinkClick = () => {
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    };
    
    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.removeItem('data.token');
        navigate('/');
    };

    return (
        <div className="dashboard-container">
            <button className="toggle-button" onClick={toggleSidebar}>
                <i className={`fa ${sidebarOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/dashboard" onClick={handleLinkClick}>Dashboard</Link></li>
                    <li><Link to="profile" onClick={handleLinkClick}>Profile</Link></li>
                    <li><Link to="web-lead" onClick={handleLinkClick}>Web Lead</Link></li>
                    <li><Link to="users" onClick={handleLinkClick}>Users</Link></li>
                    <li><Link to="send-email" onClick={handleLinkClick}>Email</Link></li>
                    <li><Link to="create-leads" onClick={handleLinkClick}>Create Leads</Link></li>
                    <li><Link to="lead-table" onClick={handleLinkClick}>Leads</Link></li>
                    <li><Link to="create-project" onClick={handleLinkClick}>Create Project</Link></li>
                    <li><Link to="traffic-dashboard" onClick={handleLinkClick}>Traffic Dashboard</Link></li>
                    <li><Link to="traffic-filter" onClick={handleLinkClick}>Traffic Filter</Link></li>
                    <li><Link to="projects" onClick={handleLinkClick}>Projects</Link></li>
                    <li><Link to="docs/offer-letter" onClick={handleLinkClick}>Offer Letter</Link></li>
                    <li><Link to="manage-pages/articles" onClick={handleLinkClick}>Manage Articles</Link></li>
                    <li><Link to="manage-pages/articles/create" onClick={handleLinkClick}>Create Article</Link></li>
                    <li><Link to="settings" onClick={handleLinkClick}>Settings</Link></li>
                    <li onClick={handleLogout}>Logout</li>
                </ul>

                <div className="user-info-bottom">
                    <div className="user-info" onClick={toggleDropdown}>
                        <div className="user-avatar">
                            <i className="fa fa-user"></i>
                        </div>
                        <span>{email}</span>
                        <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'up'}`}></i>
                    </div>

                    {dropdownOpen && (
                        <div className={`dropdown-menu ${dropdownOpen ? 'visible' : ''}`}>
                            <ul>
                                <li>Profile</li>
                                <li>Settings</li>
                                <li onClick={handleLogout}>Logout</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className="content">
                <Routes>
                    <Route path="profile" element={<Profile />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/create-user" element={<CreateUser />} />
                    <Route path="users/user/:id" element={<User />} />
                    <Route path="send-email" element={<EmailComposeForm />} />
                    <Route path="projects" element={<ListProjects />} />
                    <Route path="projects/:projectId" element={<ProjectDetails />} />
                    <Route path="create-leads" element={<LeadForm />} />
                    <Route path="traffic-filter" element={<TrafficData />} />
                    <Route path="traffic-dashboard" element={<TrafficData1 />} />
                    <Route path="docs/offer-letter" element={<OfferLetterForm />} />
                    <Route path="create-article" element={<CreateArticle />} />
                    <Route path="manage-pages/articles" element={<ArticleList />} />
                    <Route path="manage-pages/articles/create" element={<ArticleWriter />} />
                    <Route path="lead-table" element={<LeadTable />} />
                    <Route path="create-project" element={<CreateProject />} />
                    <Route path="web-lead" element={<TrafficTable />} />
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;
