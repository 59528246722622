import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TrafficTable.css'; // Import CSS file

const TrafficTable = () => {
    const [trafficData, setTrafficData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);



    useEffect(() => {
        const fetchTrafficData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get('https://api.inindia.cloud/api/filter/traffic/leads', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        page,
                        limit
                    }
                });
                setTrafficData(response.data.data);
                setTotal(response.data.total);
            } catch (error) {
                console.error('Error fetching traffic data:', error.response?.data?.message || error.message);
            }
        };

        fetchTrafficData();
    }, [page, limit]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const trimLocation = (location) => {
        const questionMarkIndex = location.indexOf('?');
        if (questionMarkIndex !== -1) {
            return location.substring(0, questionMarkIndex + 1); // include ? for context
        }
        return location;
    };


    const extractInfo = (parameters) => {
        return {
            name: parameters.name,
            email: parameters.email,
            phone: parameters.phone || parameters.number // handling different key names for phone number
        };
    };

    return (
        <div className="table-container">
            <h2>Traffic Data</h2>
            <table className="traffic-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>IP Address</th>
                        <th>Request Parameters</th>
                        <th>User Agent</th>
                        <th>Referrer</th>
                        <th>Interaction Number</th>
                        <th>Location</th>
                        <th>Source</th>
                        <th>Request Method</th>
                        <th>Response Status Code</th>
                        <th>Response Time</th>
                        <th>Unlocated</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {trafficData.map((entry) => {
                        const info = extractInfo(entry.request_parameters);
                        return (
                            <tr key={entry.id}>
                                <td>{entry.id}</td>
                                <td>{entry.ip_address}</td>
                                <td>
                                    Name: {info.name} <br />
                                    Email: {info.email} <br />
                                    Phone: {info.phone}
                                </td>
                                <td>{entry.user_agent}</td>
                                <td>{entry.referrer}</td>
                                <td>{entry.interaction_number}</td>
                                <td>{trimLocation(entry.location)}</td>

                                <td>{entry.source}</td>
                                <td>{entry.request_method}</td>
                               
                                <td>{entry.response_status_code}</td>
                                <td>{entry.response_time}</td>
                                <td>{entry.unlocated ? 'Yes' : 'No'}</td>
                                <td>{new Date(entry.created_at).toLocaleDateString()}</td>
                                <td>{new Date(entry.updated_at).toLocaleDateString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>Previous</button>
                <span>Page {page}</span>
                <button onClick={() => handlePageChange(page + 1)} disabled={page * limit >= total}>Next</button>
            </div>
        </div>
    );
};

export default TrafficTable;
