import React, { useState } from 'react';
import { HiOutlinePhone, HiOutlineChatAlt2, HiOutlineBriefcase, HiOutlineStatusOffline } from 'react-icons/hi';
import axios from 'axios'; // Import axios for making HTTP requests
import './LeadForm.css'; // Import custom CSS file for additional styling

const LeadForm = () => {
    const [formData, setFormData] = useState({
        contactNumber: '',
        requirement: '',
        leadSource: '',
        status: '',
        clientName: '',
        emailAddress: '',
        businessName: '',
        alternateNumber: '',
        whatsappNumber: '',
        state: '',
        city: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const token = sessionStorage.getItem('token');
            const response = await axios.post('https://api.inindia.cloud/api/submit-lead', formData, {
                headers: {
                    'Authorization': `Bearer ${token}` // Include the JWT token in the request headers
                }
            });

            console.log('Lead submitted:', response.data.message);

            // Clear form fields after successful submission
            setFormData({
                contactNumber: '',
                requirement: '',
                leadSource: '',
                status: '',
                clientName: '',
                emailAddress: '',
                businessName: '',
                alternateNumber: '',
                whatsappNumber: '',
                state: '',
                city: ''
            });
        } catch (error) {
            console.error('Error submitting lead:', error.response.data.message);
        }
    };

    return (
        <div className="lead-form-container">
            <h2 className="lead-form-title">Submit Lead</h2>
            <form onSubmit={handleSubmit} className="lead-form">
                <div className="lead-form-group">
                    <span className="lead-form-icon"><HiOutlinePhone /></span>
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="Contact Number"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="lead-form-group">
                    <span className="lead-form-icon"><HiOutlineChatAlt2 /></span>
                    <textarea
                        className="lead-form-input"
                        placeholder="Requirement"
                        name="requirement"
                        value={formData.requirement}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="lead-form-group">
                    <span className="lead-form-icon"><HiOutlineBriefcase /></span>
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="Lead Source"
                        name="leadSource"
                        value={formData.leadSource}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="lead-form-group">
                    <span className="lead-form-icon"><HiOutlineStatusOffline /></span>
                    <select
                        className="lead-form-select"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Status</option>
                        <option value="New">New</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Closed">Closed</option>
                    </select>
                </div>
                {/* Additional optional fields */}
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="Client Name"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="email"
                        className="lead-form-input"
                        placeholder="Email Address"
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="Business Name"
                        name="businessName"
                        value={formData.businessName}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="Alternate Number"
                        name="alternateNumber"
                        value={formData.alternateNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="WhatsApp Number"
                        name="whatsappNumber"
                        value={formData.whatsappNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="State"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                    />
                </div>
                <div className="lead-form-group">
                    <input
                        type="text"
                        className="lead-form-input"
                        placeholder="City"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="inindia-button">Submit</button>
            </form>
        </div>
    );
};

export default LeadForm;
