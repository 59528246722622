import React, { useState, useEffect } from 'react';
import './ArticleList.css';
import ArticleEditor from './ArticleEditor';

function truncateDescription(description) {
  const words = description.split(' ');
  if (words.length > 20) {
    return words.slice(0, 20).join(' ') + '...';
  }
  return description;
}

function ArticleList() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    distribution: '',
    cat: '',
    searchTerm: ''
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0
  });
  const [editingArticle, setEditingArticle] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const fetchArticles = async () => {
      try {
        const { distribution, cat, searchTerm } = filters;
        const params = new URLSearchParams({
          distribution,
          cat,
          search: searchTerm,
          limit: pagination.limit,
          page: pagination.page,
        });

        const response = await fetch(`https://api.inindia.cloud/api/articles?${params}`, {
          headers: headers, 
        });
        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }
        const { data, total } = await response.json();
        setArticles(data);
        setPagination(prevPagination => ({ ...prevPagination, total }));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchArticles();
  }, [filters, pagination.page, pagination.limit]);

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    setPagination(prevPagination => ({ ...prevPagination, page: 1 }));
  };

  const handlePreviousPage = () => {
    setPagination(prevPagination => ({
      ...prevPagination,
      page: Math.max(prevPagination.page - 1, 1)
    }));
  };

  const handleNextPage = () => {
    setPagination(prevPagination => ({
      ...prevPagination,
      page: Math.min(prevPagination.page + 1, Math.ceil(pagination.total / pagination.limit))
    }));
  };

  const handleEditArticle = async (articleId) => {
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(`https://api.inindia.cloud/api/articles/${articleId}`, { headers });
      if (!response.ok) {
        throw new Error('Failed to fetch article data');
      }
      const articleData = await response.json();
      setEditingArticle(articleData);
      setShowEditForm(true);
    } catch (error) {
      console.error('Error editing article:', error);
    }
  };

  const handleDeleteArticle = () => {
    // Logic to handle deleting an article
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Article List</h1>
      <div className="filter-container">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="text"
            name="distribution"
            value={filters.distribution}
            onChange={handleFilterChange}
            placeholder="Distribution"
          />
          <input
            type="text"
            name="cat"
            value={filters.cat}
            onChange={handleFilterChange}
            placeholder="Category"
          />
          <input
            type="text"
            name="searchTerm"
            value={filters.searchTerm}
            onChange={handleFilterChange}
            placeholder="Search Term"
          />
          <button onClick={() => setPagination(prevPagination => ({ ...prevPagination, page: 1 }))}>Apply Filters</button>
        </form>
      </div>
      <div className="article-table-container">
        <table className="article-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Distribution</th>
              <th>URL</th>
              <th>Category</th>
              <th>Page Name</th>
              <th>Subject</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {articles.map(article => (
              <tr key={article.id}>
                <td>{article.id}</td>
                <td>{article.distribution}</td>
                <td>{article.url}</td>
                <td>{article.cat}</td>
                <td>{article.page_name}</td>
                <td>{article.subject}</td>
                <td>{truncateDescription(article.description)}</td>
                <td>
                  <button onClick={() => handleEditArticle(article.id)}>Edit</button>
                  <button onClick={handleDeleteArticle}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <button onClick={handlePreviousPage} disabled={pagination.page === 1}>Previous</button>
        <span>Page {pagination.page}</span>
        <button onClick={handleNextPage} disabled={pagination.page >= Math.ceil(pagination.total / pagination.limit)}>Next</button>
      </div>
      {showEditForm && editingArticle && (
  <ArticleEditor 
    key={editingArticle.id}  // Adding the unique key
    article={editingArticle} 
    onClose={() => setShowEditForm(false)} 
  />
)}
    </div>
  );
}

export default ArticleList;
