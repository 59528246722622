import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './auth/RoleBaseLogin';
import Dashboard from './onboard/Dashboard';
import EmpDashboard from './employees/EmpDashboard.js'
import WriterDashboard from './writer/WriterDashboard.js'
import NotFoundPage from './NotFoundPage'; // Make sure to import NotFoundPage
import './App.css';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/home/*" element={<EmpDashboard />} />
        <Route path="/writer/*" element={<WriterDashboard />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Fallback route */}
        {/* <Route path="/dashboard/profile" element={<Profile />} />
                    <Route path="/dashboard/settings" element={<Settings />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
