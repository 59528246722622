import React, { useState, useEffect } from 'react';
import TrafficFilters from './TrafficFilters.js';
import TrafficTable from './TrafficTable.js';
import Pagination from './Pagination.js';

function TrafficData() {
  const [trafficData, setTrafficData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ startDate: '', endDate: '' });
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 }); // Updated default limit to 20

  useEffect(() => {
    fetchData();
  }, [filters, pagination]); // Fetch data when filters or pagination changes

  const fetchData = async () => {
    try {
      // Construct the API URL with filters and pagination parameters
      const token = sessionStorage.getItem('token');
      let url = `https://api.inindia.cloud/api/get-traffic?limit=${pagination.limit}&offset=${pagination.offset}`;
      if (filters.startDate && filters.endDate) {
        url += `&startDate=${filters.startDate}&endDate=${filters.endDate}`;
      }
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}` // Add token to the Authorization header
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTrafficData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching traffic data:', error);
    }
  };
  

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // Reset pagination to the first page when filters change
    setPagination({ ...pagination, offset: 0 });
  };

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
  };

  return (
    <div>
      <h2>Traffic Data</h2>
      <TrafficFilters onChange={handleFilterChange} />
      {loading ? <div>Loading...</div> : <TrafficTable data={trafficData} />}
      <Pagination onChange={handlePaginationChange} />
    </div>
  );
}

export default TrafficData;
