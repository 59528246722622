import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SearchComponent.css'; // Import enhanced CSS

function SearchComponent() {
    const [searchIpAddress, setSearchIpAddress] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [geolocation, setGeolocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0); // Current index of traffic data card

    // Handle Search Request
    const handleSearch = async () => {
        try {
            setLoading(true);
            const token = sessionStorage.getItem('token');

            const response = await axios.get('https://api.inindia.cloud/api/get-traffic', {
                params: { ipAddress: searchIpAddress },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const trafficData = response.data.traffic || [];
            setSearchResults(trafficData);
            setGeolocation(response.data.geolocation || null);
            setError(null);
            setCurrentIndex(0); // Reset to first card
        } catch (error) {
            setError('Error searching. Please try again later.');
            console.error('Error searching:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle Clear
    const handleClear = () => {
        setSearchIpAddress('');
        setSearchResults([]);
        setGeolocation(null);
        setError(null);
    };

    // Navigate to next card
    const nextData = () => {
        if (currentIndex < searchResults.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    // Navigate to previous card
    const prevData = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    // Keyboard navigation for carousel
    useEffect(() => {
        const handleKeydown = (e) => {
            if (e.key === 'ArrowRight') {
                nextData();
            } else if (e.key === 'ArrowLeft') {
                prevData();
            }
        };
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [currentIndex]);

    return (
        <div className="search-container">
            <h2>Search by IP Address</h2>
            <div className="input-container m-4">
                <input
                    className="search-input"
                    type="text"
                    value={searchIpAddress}
                    onChange={(e) => setSearchIpAddress(e.target.value)}
                    placeholder="Enter IP Address"
                />
                <button
                    className="search-button m-4"
                    onClick={handleSearch}
                    disabled={loading}
                >
                    {loading ? 'Searching...' : 'Search'}
                </button>
                <button className="search-button" onClick={handleClear}>Clear</button>
            </div>

            {error && <div className="error-message">{error}</div>}

            {loading && <div className="loading-spinner">Loading...</div>}

            {geolocation && (
                <div className="geolocation-data">
                    <h3>Geolocation Data</h3>
                    <p><strong>City:</strong> {geolocation.city || 'N/A'}</p>
                    <p><strong>State:</strong> {geolocation.state || 'N/A'}</p>
                    <p><strong>Country:</strong> {geolocation.country_name || 'N/A'}</p>
                    <p><strong>Latitude:</strong> {geolocation.latitude || 'N/A'}</p>
                    <p><strong>Longitude:</strong> {geolocation.longitude || 'N/A'}</p>
                </div>
            )}

            {/* Carousel for Traffic Data */}
            {Array.isArray(searchResults) && searchResults.length > 0 && (
                <div className="carousel">
                    <div className="carousel-card">
                        <p><strong>IP Address:</strong> {searchResults[currentIndex].ip_address}</p>
                        <p><strong>Location:</strong> {searchResults[currentIndex].location || 'N/A'}</p>
                        <p><strong>Request Method:</strong> {searchResults[currentIndex].request_method}</p>
                        <p><strong>Response Status Code:</strong> {searchResults[currentIndex].response_status_code}</p>
                        <p><strong>Response Time:</strong> {searchResults[currentIndex].response_time} ms</p>
                        <p><strong>Created At:</strong> {new Date(searchResults[currentIndex].created_at).toLocaleString()}</p>
                    </div>

                    {/* Pagination Dots */}
                    <div className="pagination">
                        {searchResults.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>

                    {/* Navigation Buttons */}
                    <div className="carousel-buttons">
                        <button onClick={prevData} disabled={currentIndex === 0}>Prev</button>
                        <button onClick={nextData} disabled={currentIndex === searchResults.length - 1}>Next</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SearchComponent;
