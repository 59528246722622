import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import './EditUser.css';

function EditUser() {

const { id } = useParams();


    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        role: ''
    });

console.log('userId:', userData);

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        fetch(`https://api.inindia.cloud/api/users/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            return response.json();
        })
        .then((userData) => {
            setUserData(userData);
        })
        .catch((error) => {
            setIsError(true);
            setFeedbackMessage(error.message || 'An error occurred while fetching user data');
        });
    }, [id]);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token');
        setFeedbackMessage('');
        setIsError(false);

        try {
            const response = await fetch(`https://api.inindia.cloud/api/users/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                throw new Error('Failed to update user');
            }

            // const result = await response.json();
            setFeedbackMessage('User updated successfully');
        } catch (error) {
            setIsError(true);
            setFeedbackMessage(error.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className="edit-user-container">
            <h2>Edit User</h2>
            <form onSubmit={handleSubmit} className="edit-user-form">
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={userData.name}
                        onChange={handleChange}
                        placeholder="Name"
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        placeholder="Email"
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={userData.password}
                        onChange={handleChange}
                        placeholder="Password"
                    />
                </div>
                <div>
                    <label>Role:</label>
                    <input
                        type="text"
                        name="role"
                        value={userData.role}
                        onChange={handleChange}
                        placeholder="Role"
                    />
                </div>
                <button type="submit">Update User</button>
            </form>

            {feedbackMessage && (
                <div className={`feedback-message ${isError ? 'error' : 'success'}`}>
                    {feedbackMessage}
                </div>
            )}
        </div>
    );
}

export default EditUser;
