import React, { useState } from 'react';
import axios from 'axios';
import './CreateProject.css'; // Import CSS file

const CreateProject = () => {
    const [formData, setFormData] = useState({
        lead_id: '',
        project_name: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const token = sessionStorage.getItem('token');
            const response = await axios.post(
                'https://api.inindia.cloud/api/projects',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log(response.data);
            // Reset form fields after successful project creation
            setFormData({
                lead_id: '',
                project_name: ''
            });
            alert('Project created successfully!');
        } catch (error) {
            console.error('Error creating project:', error.response.data.message);
            alert('Error creating project. Please try again.');
        }
    };

    return (
        <div className="create-project-container">
            <h2>Create New Project</h2>
            <form className="create-project-form" onSubmit={handleSubmit}>
                <label>
                    Lead ID:
                    <input
                        type="text"
                        name="lead_id"
                        value={formData.lead_id}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Project Name:
                    <input
                        type="text"
                        name="project_name"
                        value={formData.project_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit">Create Project</button>
            </form>
        </div>
    );
};

export default CreateProject;
